<template>
<div>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" />
    <div class="container">
        <b-card class="mb-3">
            <div>
                <!--h2>Welcome {{personalInfo.first_name ? `${personalInfo.first_name.charAt(0).toUpperCase()}${personalInfo.first_name.slice(1)}` : ''}} {{ organisation.name ? ` to ${organisation.name}` : '' }} {{ organisation.category ? ` ${organisation.category}` :'' }} </h2-->

                <h2>Add Staff Member {{ orgName  ? `to ${orgName}` : ''}}</h2>
                <b-form @submit.prevent="addStaffMember">
                    <b-form-group label="Email" label-for="email" class="mb-2">
                        <b-form-input id="email" v-model="formData.email" required placeholder="Enter your email"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Designation" label-for="designation" class="mb-2">
                        <b-form-input id="designation" v-model="formData.designation" placeholder="Enter your designation"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Start Date" label-for="start_date" class="mb-2">
                        <input type="date" id="start_date" required v-model="formData.start_date" class="form-control" />
                    </b-form-group>

                    <!-- Button aligned to the right -->
                    <div class="d-flex justify-content-end">
                        <b-button @click="goBack" variant="secondary">Back</b-button>
                        <b-button type="submit" variant="primary">Generate OTP</b-button>
                    </div>
                </b-form>
            </div>
        </b-card>

        <div v-if="showPopup" class="popup">
            <h4>Take a photo or screenshot of text in red box below and share it using whatsapp or other messaging service with the staff you want to add to your organisation.</h4>
            <div class="popup-inner">
                <div class="info-row">
                    <label v-if="isPhoneNumber">Phone:</label>
                    <label v-else>Email:</label>
                    <span>{{ formData.email }}</span>
                </div>
                <div class="info-row">
                    <label>OTP:</label>
                    <span>{{ otp }}</span>
                </div>
                <div class="info-row">
                    <label>Link:</label>
                    <span>{{ joinUrl }}</span>
                </div>
                <p>Open the link above and fill in your email and OTP to join.</p>
                <p>The OTP will expire in {{ expire_duration }} hour.</p>
            </div>

            <button @click="closePopup">Close</button>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import MessageBox from '../MessageBox.vue';
import { decode } from 'base-64';

export default {
    components: {
        MessageBox
    },
    data() {
        return {
            formData: {
                email: "",
                designation: "",
                start_date: null,
                organisation_id: null,
                expiry_datetime: null,
            },
            expire_duration: 1, // 1 hour
            isPhoneNumber: false,
            otp: "",
            message: "",
            orgName: "",
            showPopup: false,
            showMessageBox: true,
        };
    },
    props: {
        org: {
            type: String,
            default: null, // Default value if no org is passed
        },
    },
    mounted() {
        // Decode the parameter
        const decoded = decodeURIComponent(this.org);
        this.decodeOrg(decoded)
    },
    methods: {
        async addStaffMember() {
            try {
                const response = await axios.post('/authorization/verify', {
                    email: [this.formData.email],
                });
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    this.message = "A User with this email is already registered. Ask him to try different email or join the organisation after logging in."
                    this.$refs.messageBox.show();
                }
                return
            }

            try {
                if (!this.formData.organisation_id) {
                    alert("Organization not set is required!");
                    return;
                }

                const now = new Date();
                now.setHours(now.getHours() + this.expire_duration);
                this.formData.expiry_datetime = now.toISOString();
                const response = await axios.post("/otp/generate", this.formData);
                const baseUrl = `${window.location.protocol}//${window.location.host}`;

                this.joinUrl = `${baseUrl}/join`
                this.otp = response.data.otp;
                this.showPopup = true;
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    alert(error.response.data.detail);
                } else {
                    alert("Failed to generate OTP. Please try again.");
                }
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        decodeOrg() {
            if (this.org !== null) {
                const orgParam = decode(this.org);
                const org = orgParam.split(':');
                this.orgName = org[0];
                this.formData.organisation_id = org[1];
            }
        },
        closePopup() {
            this.showPopup = false;
        },
    },
};
</script>

<style scoped>
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25vw;
    min-width: 300px;
    border-radius: 15px;
    border: 2px solid blue;
    background: linear-gradient(to right, #f0f8ff, #e6f7ff);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.popup-inner {
    border: 1px solid red;
    padding: 10px;
    margin-bottom: 15px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.info-row label {
    font-weight: bold;
    margin-right: 10px;
}

.info-row span {
    word-break: break-all;
    flex: 1;
}
</style>
