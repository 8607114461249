<template>
<div>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" @close="handleClose" />
    <div class="container">
        <b-card class="p-3">
            <h2 v-if="addStaff">Add Staff Member to '{{ orgName }}'</h2>
            <h2 v-else>Register</h2>
            <div class="form-group">

                <form @submit.prevent="register">
                    <div>
                        <b-form-group label="Phone number or Email address:" label-for="contact">
                            <b-form-input id="contact" v-model="contact" @input="checkInputType" placeholder="Enter your phone number or Email address" required></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b-button v-if="showRegisterButton" @click="register" variant="primary">
                                Register
                            </b-button>
                        </div>
                    </div>
                </form>
                <p> {{ inputTypeMessage }}</p>
            </div>
        </b-card>
    </div>
    <!-- Popup for Add Staff -->

</div>
</template>

<script>
import axios from '@/axios.js';
import MessageBox from '../MessageBox.vue';
import { decode } from 'base-64';

export default {
    name: 'UserRegister',
    props: {
        showRegisterButton: {
            type: Boolean,
            default: true, // Show by default
        },
        org: {
            type: String,
            default: null, // Default value if no org is passed
        },
        addStaff: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        // Decode the parameter
        const decoded = decodeURIComponent(this.org);
        this.decodeOrg(decoded)
    },
    components: {
        MessageBox
    },
    data() {
        return {
            contact: '', // Binding for the input value
            isPhoneNumber: false,
            showMessageBox: true,
            inputTypeMessage: '', // Message to show input type
            expire_duration: 1, // 1 hour
            email: "",
            localFirstName: this.value,
            message: "",
            orgName: "",
            orgId: "",
        };
    },
    methods: {
        decodeOrg() {
            const orgParam = decode(this.org);
            const org = orgParam.split(':');
            this.orgName = org[0];
            this.orgId = org[1];
        },
        checkInputType() {
            const phoneRegex = /^[0-9]*$/;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isPhoneNumber = false;
            if (phoneRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Phone Number';
                this.isPhoneNumber = true;
                this.email = this.contact;
            } else if (emailRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Email';
                this.email = this.contact;
            } else {
                this.inputTypeMessage = 'Input is neither a valid Email nor Phone Number';
            }
        },
        closePopup() {
            this.showPopup = false;
        },
        handleClose() {
            if (this.verified) {
                this.$router.push({
                    name: 'EditStaff',
                    query: {
                        addStaff: true,
                        employee: encode(this.email),
                        org: this.orgId
                    }
                });
            }
        },
        async register() {
            try {
                const response = await axios.post('/authorization/verify', {
                    email: [this.email],
                });
                this.setEmailCookie(this.email);
                this.message = 'Registration email sent successfully!'

                this.$refs.messageBox.show();
                this.$router.push({
                    name: 'EmailSentNotify',
                    query: { registerEmail: this.email }
                });
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    this.message = error.response.data.detail
                    this.$refs.messageBox.show();
                } else {
                    // console.error(error);
                    this.message = 'Failed to send registration email.';
                    this.$refs.messageBox.show();
                }

            }
        },
        updateFirstName() {
            this.$emit("input", this.localFirstName);
        },
        setEmailCookie(email) {
            // Set the cookie to expire in 7 days
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7);

            // Create the cookie string
            const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;

            // Set the cookie
            document.cookie = cookieString;
        }
    }
};
</script>

<style>
.row {
    margin-bottom: 1em;
}

.col {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

button {
    padding: 0.5em 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

button {
    display: block;
    margin-top: 15px;
}
</style>
