<template>
<div class="container">
    <b-card class="p-3">
        <h3 class="mb-4">Login</h3>
        <b-form @submit="login" @reset="onReset" v-if="show">
            <b-form-group id="username-group" label="Email as Username:" label-for="username">
                <b-form-input id="username" v-model="form.username" type="text" placeholder="Enter email as username or phone number" required></b-form-input>
            </b-form-group>

            <b-form-group id="password-group" label="Password:" label-for="password">
                <b-form-input id="password" v-model="form.password" type="password" placeholder="Enter password" required></b-form-input>
            </b-form-group>

            <b-form-group id="remeberFormGroup">
                <b-form-checkbox-group v-model="form.checked" id="rememberChecked">
                    <b-form-checkbox value="remember">Remember me</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>

            <div class="d-flex !important justify-content-between !important">
                <div>
                    <b-button type="reset" variant="danger" class="custom-red-button">Reset</b-button>&nbsp;
                    <b-button type="submit" variant="primary">Login</b-button>
                </div>
                <div>
                    <router-link to="/user/reset/password">Forgot Password</router-link>

                </div>
            </div>
        </b-form>
    </b-card>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" @close="handleClose" />
</div>
</template>

<script>
import axios from '@/axios.js';
import { mapActions } from 'vuex';
import { decode } from 'base-64';
import MessageBox from '../MessageBox.vue';

export default {
    name: 'UserLogin',
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
            show: true,
            isAddStaff: false,
            showMessageBox: true,
            organisation_id: null,
            visitorIdentifier: '',
            errorMessage: '',
            message: ''
        };
    },
    components: {
        MessageBox
    },
    props: {
        org: {
            type: Number,
            default: null, // Default value if no org is passed
        },
        addStaff: {
            type: Boolean,
            default: false,
        },
        employee: {
            type: String,
            default: null,
        },
    },
    created() {
        this.isAddStaff = this.$route.query.addStaff !== undefined ? this.$route.query.addStaff : false;
        this.organisation_id = this.$route.query.org !== undefined ? this.$route.query.org : null
        this.employee = this.$route.query.employee !== undefined ? this.$route.query.employee : null
    },
    methods: {
        ...mapActions(['getCookie', 'generateRandomString']),
        async fetchVisitorIdentifier() {
            this.visitorIdentifier = await this.getCookie('visitorIdentifier');
        },
        handleClose() {
            if (this.isAddStaff === true) {

            }
        },
        async login(event) {
            event.preventDefault()
            const randomString = await this.generateRandomString(10);
            this.fetchVisitorIdentifier();
            try {
                const params = new URLSearchParams();
                params.append('username', this.form.username);
                params.append('password', this.form.password);

                if (!this.visitorIdentifier) {
                    this.setCookie('visitorIdentifier', randomString);
                    this.visitorIdentifier = randomString;
                }

                params.append('track_id', this.visitorIdentifier);
                const response = await axios.post('/authorization/login', params);
                this.setCookie('registrationEmail', this.form.username)

                // If the employee is being added we decode his email
                if (this.isAddStaff === true) {
                    const hashed_query_email = this.employee
                    this.email = decode(hashed_query_email);
                    this.$router.push({
                        name: 'EditStaff',
                        query: {
                            addStaff: true,
                            org: this.organisation_id
                        }
                    });
                    return
                }
                this.$router.push('/welcome');
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    this.errorMessage = error.response.data.detail;
                } else {
                    console.error(error);
                }
            }
        },
        onReset(event) {
            event.preventDefault()
            this.form.username = ''
            this.form.password = ''
        },
        setCookie(key, email) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7);
            const cookieString = `${key}=${email}; expires=${expirationDate.toUTCString()}; path=/`;
            document.cookie = cookieString;
        },
        register() {
            this.$router.push('/register');
        },
        reset() {
            this.$router.push('/reset/password');
        }
    }
};
</script>

<style scoped>
/* Ensure form takes up the necessary space */
.login-form {
    position: relative;
    padding-bottom: 50px;
    /* Space for buttons */
}
</style>
