<template>
<div>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" @close="handleClose" />
    <div class="container">
        <h2>Set a password to complete the registration for {{ email }}</h2>
        <h4 v-if="!addStaff">If you are seeing an email above different than the one you expected. Please <a href='/register'>click here</a> to register again.</h4>
        <form @submit.prevent="registerComplete">
            <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" v-model="password" class="form-control">
            </div>
            <div class="form-group">
                <label for="retype-password">Retype Password:</label>
                <input type="password" id="retype-password" v-model="confirmPassword" class="form-control">
            </div>
            <div v-if="!isPasswordValid" class="text-danger">
                Password must be at least 8 characters long, contain uppercase and lowercase letters, numbers, and special characters.
            </div>
            <button type="submit" class="btn btn-primary">Complete Registration</button>
        </form>
    </div>
</div>
</template>

<script>
import axios from '@/axios.js';
import MessageBox from '../MessageBox.vue';
import { encode, decode } from 'base-64';

export default {
    name: 'UserRegisterComplete',
    data() {
        return {
            email: '',
            cookieEmail: '',
            urlEmail: '',
            selectedEmail: '',
            showMessageBox: true,
            message: "",
            password: '',
            confirmPassword: '',
            registrationMessage: '',
            registrationMessageStyle: '',
            organisation_id: null,
            captured_otp_id: null,
            captured_reset_state: null,
            addStaff: null,
            verified: null,
        };
    },
    props: {
        otp_id: {
            type: Number,
            default: null, // Default value if no org is passed
        }
    },
    components: {
        MessageBox
    },
    computed: {
        isPasswordValid() {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return passwordRegex.test(this.password) && this.password === this.confirmPassword;
        }
    },
    emailMismatch() {
        return this.cookieEmail !== this.urlEmail;
    },
    email() {
        return this.selectedEmail || this.cookieEmail || this.urlEmail;
    },
    created() {
        const resetParam = this.$route.query.reset
        this.initializeEmail();
        if (resetParam !== undefined) {
            this.captured_reset_state = true;
        }
    },
    methods: {
        initializeEmail() {
            this.email = this.$route.query.email !== undefined ? this.$route.query.email : null;
            this.captured_otp_id = this.$route.query.otp_id
            // If the employee is being added we decode his email
            if (this.captured_otp_id !== null) {
                this.organisation_id = this.$route.query.org;
                return
            }
            // Get email from cookie if employee not being added.
            const cookieEmail = this.getCookie('registrationEmail');
            // Get email from URL
            const urlParams = new URLSearchParams(window.location.search);
            const urlEmail = urlParams.get('email'); // todo : get rid of this email and use employee.
            // Use cookie email if available, otherwise use URL email
            this.email = cookieEmail || urlEmail || '';
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        setEmailCookie(email) {
            // Set the cookie to expire in 7 days
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7);
            // Create the cookie string
            const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;
            // Set the cookie
            document.cookie = cookieString;
        },
        handleClose() {
            if (this.verified) {
                this.$router.push({
                    name: 'UserLogin',
                    query: {
                        addStaff: this.addStaff,
                        employee: encode(this.email),
                        org: this.organisation_id
                    }
                });
            }
        },
        async registerComplete() {
            const userRegisterData = {
                email: this.email,
                password: this.password
            }
            let url = '/authorization/create'
            if (this.captured_otp_id) {
                this.addStaff = true;
                userRegisterData.otp_id = this.captured_otp_id;
            }
            this.message = 'Registration completed successfully. Redirecting to login now.'
            if (this.captured_reset_state) {
                url = '/user/reset/password';
                this.addStaff = false;
                this.message = 'Pasword reset successfull.'
            }
            try {
                const response = await axios.post(url, userRegisterData);
                if (response.status === 201) {
                    this.setEmailCookie(this.email);
                    this.verified = true;
                    this.$refs.messageBox.show();
                    this.registrationMessage = '<b><i style="color: green;">Registration completed successfully, please <a href="/login">login now</a>.</i></b>';
                } else {
                    this.registrationMessage = '<b style="color: red;">Something bad has happened and registration has not been successful. Please try again later.</b>';
                }
                this.registrationMessageStyle = 'font-weight: bold;';
            } catch (error) {
                if (error.response.status === 409) {
                    this.message = error.response.data.detail;
                    this.$refs.messageBox.show();
                    return
                }

                this.registrationMessage = '<b style="color: red;">Something bad has happened and registration has not been successful. Please try again later.</b>';
                this.registrationMessageStyle = 'font-weight: bold;';
            }
        },
    }
};
</script>
