<template>
<div class="update-profile-form">
    <b-card class="p-3">
        <h2>Fill in your personal profile information.</h2>
        <hr>
        <form @submit.prevent="submitForm">
            <b-row>
                <b-col md="6">
                    <b-form-group label="First Name" label-for="first_name" class="mb-3">
                        <b-form-input id="first_name" v-model="formData.first_name" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Last Name" label-for="last_name" class="mb-3">
                        <b-form-input id="last_name" v-model="formData.last_name" required></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Gender" label-for="gender" class="mb-2">
                <div class="form-check form-check-inline">
                    <input type="radio" id="gender-male" value="male" v-model="formData.sex" class="form-check-input" />
                    <label class="form-check-label" for="gender-male">Male</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" id="gender-female" value="female" v-model="formData.sex" class="form-check-input" />
                    <label class="form-check-label" for="gender-female">Female</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" id="gender-undisclosed" value="undisclosed" v-model="formData.sex" class="form-check-input" />
                    <label class="form-check-label" for="gender-undisclosed">Undisclosed</label>
                </div>
            </b-form-group>

            <b-form-group label="Date of Birth" label-for="date_of_birth" class="mb-2">
                <input id="date_of_birth" type="date" required v-model="formData.dob" class="form-control" />
            </b-form-group>

            <b-form-group label="Address" label-for="address" class="mb-2">
                <b-form-input id="address" required v-model="formData.address" placeholder="First line of address"></b-form-input>
            </b-form-group>

            <b-form-group label="Postode" label-for="postcode" class="mb-2">
                <b-form-input id="postcode" required v-model="formData.postcode"></b-form-input>
            </b-form-group>

            <b-form-group label="Town/City" label-for="city" class="mb-2">
                <b-form-input id="city" required v-model="formData.city"></b-form-input>
            </b-form-group>

            <b-form-group label="State/Province/County" label-for="province" class="mb-2">
                <b-form-input id="province" required v-model="formData.province"></b-form-input>
            </b-form-group>

            <b-form-group label="Country" label-for="country" class="mb-2">
                <b-form-input id="country" required v-model="formData.country"></b-form-input>
            </b-form-group>
            <div class="d-flex justify-content-end">
                <b-button @click="goBack" variant="secondary">Back</b-button>
                <b-button type="reset" variant="danger" class="custom-red-button">Reset</b-button>
                <button type="submit">Submit</button>
            </div>
        </form>
    </b-card>
</div>
</template>

<script>
import axios from '@/axios.js';
export default {
    name: 'UserProfile',

    data() {
        return {
            formData: {
                id: -1,
                first_name: '',
                last_name: '',
                address: '',
                sex: '',
                dob: '',
                city: '',
                country: '',
                postcode: '',
                province: '',
                user_id: null,
            }
        };
    },
    computed: {

    },
    created() {

    },
    async mounted() {
        if (this.$route.query.update) {
            try {
                const response = await axios.get('/personalInfo/');
                if (response.status === 200) {
                    // Update formData with the received data
                    Object.keys(this.formData).forEach(key => {
                        if (response.data[key] !== null) {
                            this.formData[key] = response.data[key];
                        }
                    });
                }
            } catch (error) {
                if (error && error.response.status === 404) {
                    console.log(error.response.data.detail)
                    return
                }
                console.error('Error fetching personal info:', error);
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async submitForm() {
            let url = '/personalInfo/create/';
            if (this.$route.query.update) {
                url = '/personalInfo/update/user/';
            }
            try {
                const response = await axios.post(url, this.formData);
                this.goBack();
            } catch (error) {
                if (error && error.response) {
                    console.log(error.response.status, ": ", error.response.data.detail)
                } else {
                    console.error('Error updating personal profile:', error);
                }
            }
        }
    }
}
</script>

<style scoped>
.update-profile-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}
</style>
