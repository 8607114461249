<template>
<div>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" @close="handleClose" />
    <div class="container">
        <b-card class="p-3">
            <h2>Verify OTP</h2>

            <b-form @submit.prevent="verifyOTP">
                <b-form-group label="Email address or Phone number:" label-for="email" description="Enter your email address or phone number you used for registered.">
                    <input type="text" id="contact" v-model="contact" @input="checkInputType" required class="form-control" placeholder="Email or phone number" />
                </b-form-group>
                <b-form-group label="OTP:" label-for="otp" description="Enter the OTP you received">
                    <b-form-input id="otp" v-model="otp" type="text" required placeholder="Enter OTP"></b-form-input>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit" variant="primary" class="mt-3">Verify OTP</b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import MessageBox from '../MessageBox.vue';
import { encode } from 'base-64';

export default {
    name: 'JoinStaffMember',
    components: {
        MessageBox
    },
    data() {
        return {
            contact: '', // Binding for the input value
            isPhoneNumber: false,
            inputTypeMessage: '', // Message to show input type
            showMessageBox: true,
            verified: false,
            email: '',
            otp: '',
            otp_id: '',
            message: '',
            organisation_id: null, // gets set when otp is verified. 
        };
    },
    methods: {
        handleClose() {
            if (this.verified) {
                this.$router.push({
                    name: 'UserRegisterComplete',
                    query: {
                        addStaff: true,
                        employee: encode(this.email),
                        org: this.organisation_id,
                        otp_id: this.otp_id,
                    }
                });
                // console.log("sent to register complerte  with: ", this.otp_id)
            }
        },
        checkInputType() {
            const phoneRegex = /^[0-9]*$/;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isPhoneNumber = false;
            if (phoneRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Phone Number';
                this.isPhoneNumber = true;
                this.email = this.contact;
            } else if (emailRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Email';
                this.email = this.contact;
            } else {
                this.inputTypeMessage = 'Input is neither a valid Email nor Phone Number';
            }
        },
        async verifyOTP() {
            try {
                const response = await axios.post('/otp/verify/', {
                    email: this.email,
                    otp: this.otp
                });

                if (response.status === 200) {
                    this.message = `OTP Verified for ${this.email}. Set new password on the next page`
                    this.organisation_id = response.data.organisation_id;
                    this.otp_id = response.data.otp_id;
                    console.log("recieved otp id is: ", this.otp_id)
                    this.verified = true;
                    this.$refs.messageBox.show();
                    return
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    this.message = (error.response.data.detail + ". Make sure you typed correct OTP or the OTP has not expired.")
                    this.verified = false;
                } else {
                    this.message = "We apologize, we cannot not reach service to generate the OTP. Please try again later.";
                }
                this.$refs.messageBox.show();
            }
        }
    },
};
</script>
