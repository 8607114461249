<template>
  <div id="app">
    <b-navbar toggleable="md" type="dark" class="nav-background">
      <b-navbar-brand>Enlively</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/">Life Long Healthcare</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
          <div right-align><b-navbar-nav>
              <b-nav-item-dropdown text="English" disabled>
                <b-dropdown-item href="#">English</b-dropdown-item>
                <b-dropdown-item href="#">Hindi</b-dropdown-item>
                <b-dropdown-item href="#">Punjabi</b-dropdown-item>
              </b-nav-item-dropdown>
              
            <div class="logged-in-align" v-if="isLoggedIn">
                  <!-- Organization Dropdown Menu -->
                  <b-nav-item-dropdown
                    text='Organisations'
                    right
                  >
                    <b-dropdown-item href="/welcome">Dashboard</b-dropdown-item>
                    <!-- Change Submenu -->
                    <b-dropdown-item
                      v-for="(org, index) in organisations"
                      :key="org.id"
                      @click="selectOrg(org, index)"
                    >
                      {{ org.name }}
                    </b-dropdown-item>

                    <!-- Add New Organization Link -->
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" @click="goToAddOrganisation">Add New</b-dropdown-item>
                    <b-dropdown-item href="#" @click="joinExistingOrganisation">Join Existing</b-dropdown-item>
                    <!-- Medicine Links (only if user has an org) -->
                    <template v-if="organisations.length > 0">
                      <b-dropdown-divider></b-dropdown-divider>
                      <h6 class="dropdown-header">Medicine</h6>
                      <b-dropdown-item href="#" @click="goToMedicine">Add New</b-dropdown-item>
                      <b-dropdown-item href="#" @click.prevent="goToViewMedicines">Stock</b-dropdown-item>

                    </template>
                  </b-nav-item-dropdown>
                  
                  <div class="user-info" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
                    <div class="user-circle" @click="toggleDropdown">
                        {{ userEmail.charAt(0).toUpperCase() }}
                    </div>
                    <span class="dropdown-arrow">▼</span>
                    <div class="dropdown-menu" v-show="showDropdown">
                        <p>{{ userEmail }}</p>
                        <a href="#" @click.prevent="logout">Logout</a>
                    </div>
                </div>

            </div>
            
            <div v-else>
              <b-button size="lg" squared class="custom-register-button" href="/register">Register</b-button>
              <b-button size="lg" squared class="custom-red-button" href="/login">Login</b-button>
            </div>
        
      </b-navbar-nav>

      </div>
    </b-navbar>
    <router-view></router-view>
    <ConsentMessage/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BNavForm,
  BFormInput,
  BButton,
  BNavItemDropdown,
  BDropdownItem 
} from 'bootstrap-vue';
import ConsentMessage from './components/ConsentMessage.vue';
// helper function called mapGetters
export default {
  name: 'app',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BNavForm,
    BFormInput,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    ConsentMessage
  },
  data () {
    return {
      toggled: true,
      position: 'top',
      color: '#4285f4',
      showDropdown: false,
      loading: false,
    }
  },
  computed: {
    ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
            organisations: state => state.organisations,
            selectedOrganisation: state => state.selectedOrganisation,
        }),
    ...mapGetters(
            [
                'isLoggedIn',
                'userEmail',
                'organisation',
                'organisations',
            ]
        ),
  },
  created() {
    if (this.isLoggedIn) {
            console.log("User is logged in.")
        }
    else {
      console.log("User is logged out.")
    }
  },
  methods: {
      ...mapActions({
        // setLoggedIn: 'auth/setLoggedIn',
        selectOrganisation: 'selectOrganisation',
        setCookie: 'setCookie',
      }),
      goToAddOrganisation() {
        this.$router.push('/add-organisation');
      },
      goToViewMedicines(){
        if (this.$route.path !== "/medicines") {
          this.$router.push('/medicines');
        }
      },
      addNewOrganisation() {
          this.$router.push('/add-organisation');
      },
      joinExistingOrganisation() {
        this.$router.push('/staff/join/organisation');
      },
      goToMedicine(orgName, orgId) {
        this.$router.push('/medicine');
      },
      goToMedicines(newOrgId) {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('orgId', newOrgId);
        window.location.href = currentUrl.toString();
      },
      selectOrg(org, index) {
        this.selectOrganisation(org);
        this.setCookie({ orgName: org.name, orgId: org.id, index: index });
        // if (this.$route.path === "/medicines") {
        //   const currentUrl = new URL(window.location.href);
        //   currentUrl.searchParams.set('orgId', org.id);
        //   window.location.href = currentUrl.toString();
        // }
    },
    logout() {
        this.$store.dispatch('logoutAction');
    },
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    },
  },

}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

body {
    background: #EEF1F4 !important;
    font-family: 'Lato', sans-serif !important;
  }


.nav-background {
    background: #353535;
    padding: 0 14px;
    p {
      color: #FFFFFF;
      font-size: 18px;
    }
  }
  .user-info {
    margin-left: 20px;
    position: absolute;
    top: 0.5px; /* Adjust as necessary */
    right: -20px; /* Adjust as necessary */
    display: flex;
    cursor: hand;
}
  .logged-in-align {
    // position: absolute;
    // margin-left: -40px;
    // left: -10px;
    display: flex;
  }
/* Circle with first letter */
.user-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(41, 111, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
/* Dropdown arrow next to the circle */
.dropdown-arrow {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
}

/* Dropdown menu hidden by default */
.dropdown-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    display: none;
}

  /* Show dropdown when v-show is true */
.user-info .dropdown-menu{
    display: block;
}
.b-navbar-nav {
    display: flex;
    align-items: center;
  }
  /* Style for email and logout link */
.dropdown-menu p {
    margin: 0;
    font-weight: bold;
    color: #333;
}

.dropdown-menu a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.dropdown-menu a:hover {
color: #0056b3;
text-decoration: underline;
}
.spacer {
  flex-grow: 1; /* This will make the spacer take up the remaining space */
}

.custom-red-button {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}
.custom-red-button:hover {
  background-color: darkred !important;
  border-color: darkred !important;
}
.custom-register-button {
  background-color: #353535 !important;
  border-color: #353535 !important;
  color: white !important;
}
</style>