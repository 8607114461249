import Vue from 'vue';
import Router from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router'
import UserRegister from '../components/user/UserRegister.vue';
import UserRegisterComplete from '../components/user/UserRegisterComplete.vue';
import UserLogin from '../components/user/UserLogin.vue';
import WelcomeUser from '@/components/WelcomeUser.vue';
import UserAddOrganisation from '@/components/UserAddOrganisation.vue';
import StockMedicine from '../components/StockMedicine.vue';
import ViewMedicines from '@/components/ViewMedicines.vue';
import EmailSentNotify from '../components/EmailSentNotify.vue';
import JoinStaffMember from '@/components/staff/JoinStaffMember.vue';
import EditStaff from '@/components/staff/EditStaff.vue';
import UserProfile from '@/components/user/UserProfile.vue';
import AddStaff from '@/components/staff/AddStaff.vue';
import store from '../store/store';
import Home from '../components/HomeView.vue'
import UserPasswordReset from '@/components/user/UserPasswordReset.vue';


Vue.use(Router);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    redirect: '/home'
  },  
  {
    path: '/register:org?:addStaff?',
    name: 'UserRegister',
    component: UserRegister,
    props: (route) => ({ org: route.query.org, addStaff: route.query.addStaff}),
  },
  {
    path: '/emailsent/:registerEmail?',
    name: 'EmailSentNotify',
    component: EmailSentNotify
  },
  {
    path: '/complete',
    name: 'UserRegisterComplete',
    component: UserRegisterComplete,
    props: (route) => ({ email: route.query.email, reset: route.query.reset})
  },
  {
    path: '/user/profile:update?',
    name: 'UserProfile',
    component: UserProfile,
    props: (route) => ({
      update: route.query.update || null,
    }),
  },
  {
    path: '/login:org?:addStaff?:employee?',
    name: 'UserLogin',
    component: UserLogin,
    props: (route) => ({
      org: route.query.org || null,
      employee: route.query.employee || null,
      addStaff: route.query.addStaff !== undefined ? route.query.addStaff === 'true' : false,
    }),
  },
  {
    path: '/join',
    name: 'JoinStaffMember',
    component: JoinStaffMember
  },
  {
    path: '/staff/add:org?',
    name: 'AddStaff',
    component: AddStaff,
    props: (route) => ({ org: route.query.org}),
  },
  {
    path: '/staff/edit:org?:addStaff?',
    name: 'EditStaff',
    component: EditStaff,
    props: (route) => ({
      designation: route.query.designation,
      specialisations: route.query.specialisations,
      start_date: route.query.start_date,
      staffId: route.query.staffId,
      addStaff: route.query.addStaff !== undefined ? route.query.addStaff === 'true' : false,
    }),
  },
  {
    path: '/user/reset/password',
    name: 'UserPasswordReset',
    component: UserPasswordReset
  },
  {
    path: '/welcome',
    name: 'WelcomeUser',
    component: WelcomeUser
  },
  {
    path: '/add-organisation',
    name: 'AddOrganisation',
    component: UserAddOrganisation
  },
  {
    path: '/medicine',
    name: 'StockMedicine',
    component: StockMedicine
  },
  {
    path: '/medicines',
    name: 'ViewMedicines',
    component: ViewMedicines,
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;
