<template>
<div>
    <b-button @click="goBack" variant="secondary">Back</b-button>
    <!-- Search Bar -->
    <b-form-group label="Search Medicine:" label-for="search-input">
        <b-form-input id="search-input" v-model="searchQuery" placeholder="Type to search..." @input="filterMedicines"></b-form-input>
    </b-form-group>

    <!-- Items per page dropdown -->
    <b-form-group label="Items per page:" label-for="items-per-page">
        <b-form-select id="items-per-page" v-model="itemsPerPage" :options="itemsPerPageOptions" @change="fetchMedicines"></b-form-select>
    </b-form-group>

    <!-- Medicines List Table -->
    <b-table :items="filteredMedicines" :fields="tableFields" :per-page="itemsPerPage" :current-page="currentPage">
        <template #cell(name)="data">
            <b-link @click="navigateToEdit(data.item)">{{ data.item.name }}</b-link>
        </template>
    </b-table>

    <!-- Pagination Control -->
    <b-pagination v-model="currentPage" :total-rows="filteredMedicines.length" :per-page="itemsPerPage" aria-controls="medicine-table"></b-pagination>
</div>
</template>

<script>
import axios from '@/axios.js';
import { mapGetters, mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
            selectedOrganisation: state => state.selectedOrganisation
        }),
        ...mapGetters(
            [
                'isLoggedIn',
                'userEmail',
                'organisation',
            ]
        )
    },
    watch: {
        selectedOrganisation(newOrg, oldOrg) {
            // Check if the organization actually changed
            if (newOrg && newOrg !== oldOrg) {
                this.fetchMedicines();
            }
        }
    },
    data() {
        return {
            medicines: [],
            filteredMedicines: [],
            searchQuery: "",
            itemsPerPage: 10,
            itemsPerPageOptions: [10, 20, 50, 100],
            currentPage: 1,
            tableFields: [
                { key: "id", label: "Index" },
                { key: "name", label: "Medicine Name" },
                { key: "quantity", label: "Quantity" },
                { key: "expiry_date", label: "Expiry Date" }
            ]
        };
    },
    methods: {
        async fetchMedicines() {
            if (!this.selectedOrganisation) {
                return; // Exit if no org selected
            }
            try {
                const response = await axios.get(`/medicine/organisation/${this.$store.state.organisation.id}/`);
                this.medicines = response.data;
                this.applyFilters();
            } catch (error) {
                console.error("Error getting medicines:", error);
            }
        },
        async init() {
            // Initialise the organisation from the cookie storage
            await this.$store.dispatch('initializeOrganisation');
            // when organisation is know then we fetch the medicinces
            this.fetchMedicines();
        },
        filterMedicines() {
            this.currentPage = 1;
            this.applyFilters();
        },
        applyFilters() {
            const query = this.searchQuery.toLowerCase();
            this.filteredMedicines = this.medicines.filter((medicine) =>
                medicine.name.toLowerCase().includes(query)
            );
        },
        goBack() {
            this.$router.go(-1);
        },
        navigateToEdit(medicine) {
            this.$router.push({
                path: "/medicine",
                query: {
                    id: medicine.id,
                    name: medicine.name,
                    composition: medicine.composition,
                    manufacturer: medicine.manufacturer,
                    is_for_adult: medicine.is_for_adult,
                    expiry_date: medicine.expiry_date
                }
            });
        }
    },
    watch: {
        itemsPerPage() {
            this.currentPage = 1;
        }
    },
    mounted() {
        // code moved to init that was not doing any dom manupulation.
    },
    created() {
        this.init();
    }
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
