<template>
<div class="container">
    <h2 :key="welcomeMessage">{{ welcomeMessage }}</h2>
    <div v-if="isLoggedIn">
        <div v-if="showHighlight" class="highlight-message">
            <div class="message-content">
                <span>Profile info is not complete.<br>Click <a href='#' @click.prevent='updateProfile'>here</a> to update your profile info.</span>
                <button @click="closeHighlight" class="close-btn">&times;</button>
            </div>
        </div>
        <div v-if="showIfStaffInfo" class="highlight-message">
            <div class="message-content">
                <span>Staff info is not complete.<br>Click <a href='#' @click.prevent='joinExistingOrganisation'>here</a> to update your staff info.</span>
                <button @click="closeHighlight" class="close-btn">&times;</button>
            </div>
        </div>
    </div>
    <hr>
    <div v-if="isLoggedIn">
        <b-tabs ref="tabs" pills card vertical v-if="organisations.length">
            <b-tab v-for="(org, index) in organisations" :key="org.id" :title="org.name" :active="index === activeIndex">
                <b-card-text>
                    <div v-if="!staffInfo.staffId">
                        <svg text="muted" aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" class="octicon octicon-people">
                            <path d="M2 5.5a3.5 3.5 0 1 1 5.898 2.549 5.508 5.508 0 0 1 3.034 4.084.75.75 0 1 1-1.482.235 4 4 0 0 0-7.9 0 .75.75 0 0 1-1.482-.236A5.507 5.507 0 0 1 3.102 8.05 3.493 3.493 0 0 1 2 5.5ZM11 4a3.001 3.001 0 0 1 2.22 5.018 5.01 5.01 0 0 1 2.56 3.012.749.749 0 0 1-.885.954.752.752 0 0 1-.549-.514 3.507 3.507 0 0 0-2.522-2.372.75.75 0 0 1-.574-.73v-.352a.75.75 0 0 1 .416-.672A1.5 1.5 0 0 0 11 5.5.75.75 0 0 1 11 4Zm-5.5-.5a2 2 0 1 0-.001 3.999A2 2 0 0 0 5.5 3.5Z"></path>
                        </svg> &nbsp; <a href="#" @click.prevent="gotToAddStaff(org.name, org.id)">Add Staff Member</a>
                    </div>
                    <svg text="muted" aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" class="octicon octicon-package">
                        <path d="M8.878.392a1.75 1.75 0 0 0-1.756 0l-5.25 3.045A1.75 1.75 0 0 0 1 4.951v6.098c0 .624.332 1.2.872 1.514l5.25 3.045a1.75 1.75 0 0 0 1.756 0l5.25-3.045c.54-.313.872-.89.872-1.514V4.951c0-.624-.332-1.2-.872-1.514L8.878.392ZM7.875 1.69a.25.25 0 0 1 .25 0l4.63 2.685L8 7.133 3.245 4.375l4.63-2.685ZM2.5 5.677v5.372c0 .09.047.171.125.216l4.625 2.683V8.432Zm6.25 8.271 4.625-2.683a.25.25 0 0 0 .125-.216V5.677L8.75 8.432Z"></path>
                    </svg> &nbsp; <a href="#" @click.prevent="goToMedicine(org.name, org.id)">Add Medicine</a>
                    <br />
                    <svg text="muted" aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" class="octicon octicon-list-unordered">
                        <path d="M5.75 2.5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5Zm0 5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5Zm0 5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5ZM2 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM2 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
                    </svg> &nbsp; <a href="#" @click.prevent="goToViewMedicines()">View Medicines</a>
                </b-card-text>
            </b-tab>
            <b-tab v-if="organisations.length === 0" title="Add New" @click.prevent="addNewOrganisation">
                <b-card-text>
                    <a href="#" @click.prevent="addNewOrganisation">Click to add a new organisation</a>
                </b-card-text>
            </b-tab>
        </b-tabs>

        <div v-else-if="!loading">
            <p>{{ this.organisations_message }}</p>
            <p>Organisation</p>
            <div class="d-flex justify-content-between">
                <button @click="addNewOrganisation" class="btn btn-primary">
                    Add
                </button>
                <button @click="joinExistingOrganisation" class="btn btn-primary">
                    Join
                </button>
            </div>
        </div>
    </div>
    <div v-else>
        <p class="text-danger">Not authenticated. Please login again.</p>
    </div>
</div>
</template>

<script>
import MessageBox from './MessageBox.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { encode } from 'base-64';
import axios from 'axios';

export default {
    name: 'WelcomeUser',
    components: {
        MessageBox
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
            organisation: state => state.organisation,
            organisations: state => state.organisations,
            selectedOrganisation: state => state.selectedOrganisation,
        }),
        ...mapGetters(
            [
                'isLoggedIn',
                'userEmail',
                'organisations',
                'organisation',
                'selectedOrganisation'
            ]
        ),
        welcomeMessage() {
            return `Welcome ${this.personalInfo.first_name ? this.personalInfo.first_name.charAt(0).toUpperCase() + this.personalInfo.first_name.slice(1) : ''}${this.organisation.name ? ` to ${this.organisation.name}` : ''}${this.organisation_category ? ` ${this.organisation_category}` : ''}`;
        }
    },
    watch: {
        selectedOrganisation(newOrg) {
            if (newOrg) {
                // Find the index of the selected organization in the organisations array
                const index = this.organisations.findIndex(org => org.id === newOrg.id);
                if (index !== -1) {
                    // set the active tab to the new organization's index
                    this.activeIndex = index;
                }
            }
        }
    },
    data() {
        return {
            staffInfo: {
                designation: '',
                specialisations: null,
                startDate: null,
                staffId: null,
            },
            error: null,
            showHighlight: true,
            showIfStaffInfo: true,
            loading: false,
            personalInfo: {},
            showDropdown: false,
            organisations_message: '',
            organisation_category: '',
            activeIndex: 0, // FIXME: This sets the UI to first tab, but default state is still different in vuex.
        };
    },
    created() {
        if (this.isLoggedIn) {
            this.$store.dispatch('fetchOrganisations'); // Fetch organisations via Vuex action
        }

        // investigate why calling the line below fix the issue
        this.isLoggedIn
    },
    methods: {
        ...mapActions({
            selectOrganisation: 'selectOrganisation',
            setCookie: 'setCookie',
        }),
        closeHighlight() {
            this.showHighlight = false;
        },
        updateProfile() {
            this.$router.push({
                name: 'UserProfile',
                query: { update: true }
            });
        },
        selectOrg(org, index) {
            this.setCookie({ orgName: org.name, orgId: org.id, index: index });
            this.activeIndex = index
            this.organisation_category = org.category;
        },
        getActiveTab() {
            const activeTab = this.$refs.tabs.$children.find(tab => tab.isActive);
        },
        logout() {
            this.$store.dispatch('logoutAction');
            this.removeEmailCookie();
            this.$router.push('/login');
        },
        gotToAddStaff(orgName, orgId) {
            const combined = `${orgName}:${orgId}`;
            const hash = encode(combined);
            this.$router.push({
                name: 'AddStaff',
                query: { org: hash }
            });
        },
        goToViewMedicines() {
            this.$router.push('/medicines');
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        addNewOrganisation() {
            this.$router.push('/add-organisation');
        },
        joinExistingOrganisation(orgName, orgId) {
            // const combined = `${orgName}:${orgId}`;
            // const hash = encode(combined);
            this.$router.push({
                name: 'EditStaff',
                query: {
                    designation: this.staffInfo.designation,
                    specialisations: this.staffInfo.specialisations,
                    startDate: this.staffInfo.startDate,
                    staffId: this.staffInfo.staffId,
                }
            });
        },
        goToMedicine(orgName, orgId) {
            const combined = `${orgName}:${orgId}`;
            const hash = encode(combined);
            this.$router.push({
                name: 'StockMedicine',
                params: { org: hash }
            });
        },
        removeEmailCookie() {
            localStorage.removeItem('registrationEmail');
        },
        async checkAuth() {
            try {
                await this.$store.dispatch('checkAuthentication');
                await this.$store.dispatch('fetchOrganisations');
                // The auth state will be updated automatically if the check is successful
            } catch (error) {
                console.error('Error checking authentication:', error);
            }
        }
    },
    async mounted() {

        // check authentication when the component mounts
        await this.checkAuth();

        await this.$store.dispatch('initializeOrganisation');
        try {
            const respPersonalInfo = await axios.get('/personalInfo/user/')
            if (respPersonalInfo.status === 200) {
                this.personalInfo = respPersonalInfo.data;
                const requiredFields = ['first_name', 'last_name', 'dob', 'sex', 'address', 'province', 'postcode', 'city', 'country'];
                this.showHighlight = requiredFields.some(field => !respPersonalInfo.data[field]);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                this.showHighlight = true;
            }
        }
        // workaround fix me by updating in store.js organiastion to have all data.
        const matchingOrg = this.organisations.find(
            (org) => String(org.id) === this.organisation.id
        );
        if (matchingOrg) {
            // Use Object.assign to copy all attributes from matchingOrg to this.organisation
            Object.assign(this.organisation, matchingOrg);
        }

        if (this.organisations.length === 0) {
            const staffResponse = await axios.get("/staff/userId/")

            if (staffResponse.status === 200) {
                const requiredFields = ['designation', 'specialisations', 'start_date', ];
                this.showIfStaffInfo = requiredFields.some(field => !staffResponse.data[field]);
                this.staffInfo.designation = staffResponse.data.designation;
                this.staffInfo.specialisations = staffResponse.data.specialisations;
                this.staffInfo.startDate = staffResponse.data.start_date;
                this.staffInfo.staffId = staffResponse.data.id;
                const orgResponse = await axios.get(`/organisation/${staffResponse.data.organisation_id}`)
                if (orgResponse.status === 200) {
                    this.selectOrganisation(orgResponse.data);
                    this.$store.dispatch('fetchOrganisations', orgResponse.data);
                    this.selectOrg(orgResponse.data, 1)

                }
            }
        } else {
            this.showIfStaffInfo = false;
            this.selectOrg(this.organisations[this.activeIndex], this.activeIndex)
        }
    },
};
</script>

<style scoped>
.container {
    margin-top: 50px;
    position: relative;
}

.highlight-message {
    background-color: #9f8a53;
    border-left: 6px solid #ffc107;
    height: min-content;
    color: #383232;
    text-emphasis-color: #111111;
}

.message-content {
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
    align-items: center;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #c5a546;
}

.close-btn:hover {
    color: #000000;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    /* Align buttons with space between them */
    margin-top: 10px;
}

.btn {
    flex: 0 0 45%;
    /* Ensure buttons take up nearly equal space */
    padding: 10px 20px;
}

.user-info {
    position: absolute;
    top: 0.5px;
    /* Adjust as necessary */
    right: 10px;
    /* Adjust as necessary */
    display: flex;
    cursor: hand;
}

.no-org-container {
    position: relative;
    min-height: 100px;
}

.add-org-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgb(41, 111, 243);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

/* Circle with first letter */
.user-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(41, 111, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

/* Dropdown arrow next to the circle */
.dropdown-arrow {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
}

/* Dropdown menu hidden by default */
.dropdown-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    display: none;
}

/* Show dropdown when v-show is true */
.user-info .dropdown-menu {
    display: block;
}

/* Style for email and logout link */
.dropdown-menu p {
    margin: 0;
    font-weight: bold;
    color: #333;
}

.dropdown-menu a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.dropdown-menu a:hover {
    color: #0056b3;
    text-decoration: underline;
}
</style>
