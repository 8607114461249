<template>
<div>
    <MessageBox v-if="showMessageBox" ref="messageBox" :message="message" @close="handleClose" />
    <div class="container">
        <b-card class="p-3">
            <h2>Forgot Password</h2>
            <div>
                Enter your registered email address, and we’ll send you a link to reset your password.
            </div>
            <div class="form-group">

                <form @submit.prevent="resetPassword">
                    <div>
                        <b-form-group label="Phone number or Email address:" label-for="contact">
                            <b-form-input id="contact" v-model="contact" @input="checkInputType" placeholder="Enter your phone number or Email address" required></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b-button @click="resetPassword" variant="primary">
                                Reset Password
                            </b-button>
                        </div>
                    </div>
                </form>
                <p> {{ inputTypeMessage }}</p>
            </div>
        </b-card>
    </div>
    <!-- Popup for Add Staff -->

</div>
</template>

<script>
import axios from '@/axios.js';
import MessageBox from '../MessageBox.vue';

export default {
    name: 'UserPasswordReset',
    components: {
        MessageBox
    },
    data() {
        return {
            contact: '', // Binding for the input value
            isPhoneNumber: false,
            showMessageBox: true,
            inputTypeMessage: '', // Message to show input type
            email: "",
            userFound: true,
            message: "",
        };
    },
    methods: {
        checkInputType() {
            const phoneRegex = /^[0-9]*$/;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isPhoneNumber = false;
            if (phoneRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Phone Number, currently not supported. Use email address.';
                this.isPhoneNumber = true;
                this.email = this.contact;
            } else if (emailRegex.test(this.contact)) {
                this.inputTypeMessage = 'Detected input as Email.';
                this.email = this.contact;
            } else {
                this.inputTypeMessage = 'Input is neither a valid Email nor Phone Number';
            }
        },
        closePopup() {
            this.showPopup = false;
        },
        handleClose() {
            if (this.userFound) {
                this.goBack();
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        async resetPassword() {
            try {
                const response = await axios.post('/user/exists', {
                    email: [this.email],
                });
                if (response.status === 202) {
                    try {
                        const response = await axios.post('/authorization/verify', {
                            email: [this.email], reset: true
                        });
                        this.message = 'Please check your email. Follow the instructions to reset your password.'
                        this.$refs.messageBox.show();
                    } catch (error) {
                        if (error.response && error.response.status === 409) {
                            this.message = error.response.data.detail
                            this.$refs.messageBox.show();
                        } else {
                            // console.error(error);
                            this.message = 'Failed to send registration email.';
                            this.$refs.messageBox.show();
                        }

                    }
                } else {
                    this.userFound = false;
                }

            } catch (error) {
                this.userFound = false;
                if (error.response && error.response.status === 400) {
                    this.message = error.response.data.detail
                    this.$refs.messageBox.show();
                } else {
                    // console.error(error);
                    this.message = 'Failed to send registration email.';
                    this.$refs.messageBox.show();
                }

            }
        },
        updateFirstName() {
            this.$emit("input", this.localFirstName);
        },
    }
};
</script>

<style>
.row {
    margin-bottom: 1em;
}

.col {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

button {
    padding: 0.5em 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

button {
    display: block;
    margin-top: 15px;
}
</style>
