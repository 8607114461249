<template>
<div class="container">
    <b-card class="mb-3">
        <b-form @submit.prevent="updateStaffInfo">

            <b-form-group label="Designation" label-for="designation">
                <b-form-input id="designation" v-model="localDesignation" :disabled="isDesignationFromQuery" placeholder="Enter your designation"></b-form-input>
            </b-form-group>

            <b-form-group label="Specialisations" label-for="specialisations">
                <b-form-input id="specialisations" v-model="localSpecialisations" @input="updateSpecialisations" placeholder="Enter Specialisations" required></b-form-input>
            </b-form-group>

            <b-form-group label="Start Date" label-for="start_date" class="mb-2">
                <input type="date" id="start_date" required v-model="localStartDate" :disabled="isStartDateFromQuery" class="form-control" />
            </b-form-group>

            <b-form-group label="End Date" label-for="end_date">
                <input type="date" id="end_date" required v-model="localEndDate"  disabled class="form-control" />
            </b-form-group>

            <div class="d-flex justify-content-end">
                <b-button @click="goBack" variant="secondary">Back</b-button>
                <b-button type="submit" variant="primary">Save</b-button>
            </div>
        </b-form>
    </b-card>
</div>
</template>

<script>
import { decode } from 'base-64';
import axios from 'axios';

export default {
    props: {
        designation: String,
        specialisations: String,
        startDate: String,
        endDate: String,
        staffId: Number,
        addStaff: {
            type: Boolean,
            default: null,
        },
        org: {
            type: String,
            default: null, // Default value if no org is passed
        },
    },
    data() {
        return {
            localDesignation: this.designation,
            localSpecialisations: this.specialisations,
            localStartDate: this.startDate,
            localEndDate: this.endDate,
            localstaffId: this.staffId,
            isDesignationFromQuery: false,
            isStartDateFromQuery: false,
        };
    },
    created() {
        const designationFromQuery = this.$route.query.designation;
        if (designationFromQuery) {
            this.localDesignation = designationFromQuery;
            this.isDesignationFromQuery = true;
        } else {
            this.localDesignation = this.designation;
        }
        const startDateFromQuery = this.$route.query.startDate;
        if (startDateFromQuery) {
            this.localStartDate = startDateFromQuery;
            this.isStartDateFromQuery = true;
        } else {
            this.localStartDate = this.startDate;
        }
    },
    methods: {
        async updateStaffInfo() {
            try {
                const response = await axios.post('/staff/update/', {
                    designation: this.localDesignation,
                    specialisations: [this.localSpecialisations],
                    start_date: this.localStartDate,
                    id: this.localstaffId,
                    end_date: this.localEndDate
                });
                this.goBack();
            } catch (error) {
                // console.log("Failed to update staff info: ", error);
                // if (error.response && error.response.status === 409) {

                // }
                return
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        updateDesignation() {
            this.$emit("update:designation", this.localDesignation);
        },
        updateSpecialisations() {
            this.$emit("update:specialisations", this.localSpecialisations);
        },
        updateStartDate() {
            this.$emit("update:start-date", this.localStartDate);
        },
        updateEndDate() {
            this.$emit("update:end-date", this.localEndDate);
        },
    },
};
</script>

<style scoped>
.send-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}
</style>
