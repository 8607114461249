<template>
<div class="add-organisation-form">
    <b-card class="p-3">
        <h2>Add New Organisation</h2>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="name">Name<span class="required">*</span>:</label>
                <input v-model="form.name" type="text" id="name" required>
            </div>
            <div class="form-group">
                <label for="email">Email<span class="required">*</span>:</label>
                <input v-model="form.email" type="email" id="email" required>
            </div>
            <div class="form-group">
                <label for="organisation_type">Organisation Type<span class="required">*</span>:</label>
                <select v-model="form.organisation_type" id="organisation_type" required>
                    <option v-for="orgType in orgTypes" :key="orgType" :value="orgType">{{ orgType }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="street_address">Street Address<span class="required">*</span>:</label>
                <input v-model="form.street_address" type="text" id="street_address" required>
            </div>
            <div class="form-group">
                <label for="city_town">City/Town<span class="required">*</span>:</label>
                <input v-model="form.city_town" type="text" id="city_town" required>
            </div>
            <div class="form-group">
                <label for="province">Province/State/County<span class="required">*</span>:</label>
                <input v-model="form.province" type="text" id="province" required>
            </div>
            <div class="form-group">
                <label for="country">Country<span class="required">*</span>:</label>
                <input v-model="form.country" type="text" id="country" required>
            </div>
            <div class="form-group">
                <label for="postcode">Postcode<span class="required">*</span>:</label>
                <input v-model="form.postcode" type="text" id="postcode" required>
            </div>
            <div class="form-group">
                <label for="number_of_employees">Number of Employees<span class="required">*</span>:</label>
                <input v-model.number="form.number_of_employees" type="number" id="number_of_employees" required min="1">
            </div>
            <div class="form-group">
                <label for="government_registration">Government Registration:</label>
                <input v-model="form.government_registration" type="text" id="government_registration">
            </div>
            <div class="form-group">
                <label for="plan">Plan<span class="required">*</span>:</label>
                <select v-model="form.plan" id="plan" required>
                    <option v-for="plan in plans" :key="plan" :value="plan">{{ plan }}</option>
                </select>
            </div>
            <div class="d-flex justify-content-end">
                <b-button @click="goBack" variant="secondary">Back</b-button>
                <button type="submit">Submit</button>
            </div>
        </form>
    </b-card>
</div>
</template>

<script>
import axios from '@/axios.js';
import { mapState, mapActions } from 'vuex';
import { encode, decode } from 'base-64';

export default {
    data() {
        return {
            form: {
                isLoggedIn: false,
                name: '',
                email: '',
                organisation_type: '',
                street_address: '',
                city_town: '',
                province: '',
                country: '',
                postcode: '',
                number_of_employees: 1,
                government_registration: '',
                plan: 'Free Trial'
            },
            plans: [],
            orgTypes: []
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn
        })
    },
    created() {
        // TODO: this needs fixing, this.isLoggedIn should be true
        // if (!this.isLoggedIn) {
        //     this.$router.push('/login');
        //     }
        this.fetchOrgTypes();
        this.fetchPlans();
    },
    methods: {
        ...mapActions({
            setLoggedIn: 'auth/setLoggedIn'
        }),
        async fetchPlans() {
            try {
                const response = await axios.get('/organisation/plans');
                this.plans = response.data;
                if (this.plans.length > 0) {
                    this.form.plan = this.plans[0];
                }
            } catch (error) {
                console.error('Error getting plans:', error);
                this.form.plan = "FREE_TRIAL";
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        async fetchOrgTypes() {
            try {
                const response = await axios.get('/organisation/types/');
                const allowedTypes = ['CLINIC', 'HOSPITAL', 'PHARMACIST'];
                this.orgTypes = response.data.filter(orgType => allowedTypes.includes(orgType));
                this.orgTypes.push("")
                if (this.orgTypes.length > 0) {
                    this.form.organisation_type = this.orgTypes[-1];
                }
            } catch (error) {
                console.error('Error getting organisation types:', error);
            }
        },
        async submitForm() {
            try {
                const response = await axios.post('/organisation/add/', this.form);
                this.$router.push('/welcome');
            } catch (error) {
                console.error('Error adding organisation:', error);
            }
        }
    }
}
</script>

<style scoped>
.add-organisation-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.required {
    color: red;
}

button {
    background-color: rgb(53, 106, 177);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}
</style>
